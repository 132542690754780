/**
 * @author Irakli Kandelaki
 */

/**
 * @summary Check swipe directions, with touch event only.
 */

import { ref } from "vue";
import type { Ref } from "vue";

enum DIRECTIONS {
    L = "LEFT",
    R = "RIGHT",
    D = "DOWN",
    U = "UP",
}

interface TouchValues {
    touchX: number;
    touchY: number;
}

export const handleSwipeDirection = (): {
    handleSwipeStart: (e: TouchEvent) => TouchValues;
    handleSwipeEnd: (e: TouchEvent) => TouchValues;
    handleMouseSwipeStart: (e: MouseEvent) => void;
    handleMouseSwipeEnd: (e: MouseEvent) => void;

    direction: Ref<string | null>;
} => {
    const direction: Ref<string | null> = ref(null);
    let touchStartX: number = 0;
    let touchEndX: number = 0;
    let touchStartY: number = 0;
    let touchEndY: number = 0;
    let mouseXStart: number = 0;
    let mouseXEnd: number = 0;

    const handleSwipeStart = (e: TouchEvent): TouchValues => {
        touchStartX = e.changedTouches[0].pageX;
        touchStartY = e.changedTouches[0].pageY;

        return {
            touchX: e.changedTouches[0].pageX,
            touchY: e.changedTouches[0].pageY,
        };
    };

    const handleSwipeEnd = (e: TouchEvent): TouchValues => {
        touchEndX = e.changedTouches[0].pageX;
        touchEndY = e.changedTouches[0].pageY;
        if (
            Math.abs(touchEndX) > Math.abs(touchStartX) &&
            Math.abs(touchEndX) - Math.abs(touchStartX) >= 100
        ) {
            direction.value = null;
            setTimeout(() => {
                direction.value = DIRECTIONS.R;
            }, 0);
        } else if (
            Math.abs(touchStartX) > Math.abs(touchEndX) &&
            Math.abs(touchStartX) - Math.abs(touchEndX) >= 100
        ) {
            direction.value = null;
            setTimeout(() => {
                direction.value = DIRECTIONS.L;
            }, 0);
        } else if (
            Math.abs(touchStartY) > Math.abs(touchEndY) &&
            Math.abs(touchStartY) - Math.abs(touchEndY) >= 100
        ) {
            direction.value = null;
            setTimeout(() => {
                direction.value = DIRECTIONS.U;
            }, 0);
        } else if (
            Math.abs(touchEndY) > Math.abs(touchStartY) &&
            Math.abs(touchEndY) - Math.abs(touchStartY) >= 100
        ) {
            direction.value = null;
            setTimeout(() => {
                direction.value = DIRECTIONS.D;
            }, 0);
        }

        return {
            touchX: e.changedTouches[0].pageX,
            touchY: e.changedTouches[0].pageY,
        };
    };
    const handleMouseSwipeStart = (e: MouseEvent) => {
        mouseXStart = e.clientX;
    };
    const handleMouseSwipeEnd = (e: MouseEvent) => {
        mouseXEnd = e.clientX;
        if (Math.abs(mouseXStart - mouseXEnd) < 150) return;
        direction.value = null;
        if (mouseXStart > mouseXEnd) {
            setTimeout(() => {
                direction.value = DIRECTIONS.R;
            }, 0);
        } else {
            setTimeout(() => {
                direction.value = DIRECTIONS.L;
            }, 0);
        }
    };

    return {
        handleSwipeStart,
        handleSwipeEnd,
        handleMouseSwipeStart,
        handleMouseSwipeEnd,
        direction,
    };
};
